<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="1400px"
    v-if="isDataLoadedFromServer"
    style="background-color: white !important; height: 80vh !important"
  >
    <div class="bg-white poppins modal-root">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Create Order</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>

      <div class="d-flex flex-column mx-5 modal-body">
        <!--begin::Body-->
        <v-stepper
          v-model="tab"
          non-linear
          outlined
          rounded="4"
          flat
          v-if="isStepperReady"
          @change="updateValidationStateOfSteps"
        >
          <v-stepper-header class="step-head-main">
            <v-stepper-step
              v-for="(item, i) in categories"
              :key="i"
              class="step-head"
              complete
              editable
              complete-icon="mdi-check-circle"
              edit-icon="mdi-check"
              error-icon="mdi-alert-circle"
              :step="i"
              :rules="[
                () => {
                  return item.valid;
                },
              ]"
            >
              {{ item.name }}
              <small v-if="!item.valid">Invalid</small>
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items class="">
            <!-- categories: ["Finance", "Cosignee", "Sku", "Address", "Order Details"], -->
            <v-stepper-content step="0" key="Order Details" class="pt-2">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    class
                    v-model="order_details.client_reference"
                    label="Client reference"
                    clearable
                    outlined
                    dense
                    :error-messages="client_referenceErrors"
                    @input="$v.order_details.client_reference.$touch()"
                    @blur="$v.order_details.client_reference.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4" v-if="!isClient">
                  <v-autocomplete
                    v-model="order_details.customer_id"
                    label="Customer"
                    item-text="text"
                    item-value="index"
                    :items="serverData.customers"
                    clearable
                    outlined
                    dense
                    :error-messages="customer_idErrors"
                    @input="$v.order_details.customer_id.$touch()"
                    @blur="$v.order_details.customer_id.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.warehouse"
                    label="Warehouse"
                    item-text="text"
                    item-value="index"
                    :items="serverData.warehouses"
                    clearable
                    outlined
                    dense
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.order_type"
                    label="Order type"
                    item-text="text"
                    item-value="index"
                    :items="serverData.orderTypes"
                    clearable
                    outlined
                    dense
                    :error-messages="order_typeErrors"
                    @blur="$v.order_details.order_type.$touch()"
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.carrier_option"
                    label="Carrier option"
                    item-text="text"
                    item-value="index"
                    :items="serverData.carrier_options"
                    outlined
                    dense
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    class
                    v-model="order_details.awb"
                    label="AWB"
                    clearable
                    outlined
                    dense
                  />
                </div>

                <div
                  class="col-12 col-sm-6"
                  :class="
                    order_details.payment_type == 'COD'
                      ? 'col-md-3'
                      : 'col-md-4'
                  "
                >
                  <v-autocomplete
                    v-model="order_details.shipping_method"
                    label="Shipping method"
                    item-text="text"
                    item-value="index"
                    :items="serverData.shipping_methods"
                    clearable
                    outlined
                    dense
                    :error-messages="shipping_methodErrors"
                    @input="$v.order_details.shipping_method.$touch()"
                    @blur="$v.order_details.shipping_method.$touch()"
                  />
                </div>

                <div
                  class="col-12 col-sm-6"
                  :class="
                    order_details.payment_type == 'COD'
                      ? 'col-md-3'
                      : 'col-md-4'
                  "
                >
                  <v-autocomplete
                    v-model="order_details.payment_type"
                    label="Payment Type"
                    item-text="text"
                    item-value="text"
                    :items="serverData.paymentTypes"
                    clearable
                    outlined
                    dense
                    :error-messages="payment_typeErrors"
                    @input="$v.order_details.payment_type.$touch()"
                    @blur="$v.order_details.payment_type.$touch()"
                  />
                </div>
                <div
                  class="col-12 col-sm-6 col-md-3"
                  v-if="order_details.payment_type == 'COD'"
                >
                  <v-autocomplete
                    v-model="order_details.payment_mode"
                    label="Payment Mode"
                    item-text="text"
                    item-value="index"
                    :items="serverData.payment_modes"
                    clearable
                    outlined
                    dense
                    :error-messages="payment_modeErrors"
                    @input="$v.order_details.payment_mode.$touch()"
                    @blur="$v.order_details.payment_mode.$touch()"
                  />
                </div>
                <div
                  class="col-12 col-sm-6"
                  :class="
                    order_details.payment_type == 'COD'
                      ? 'col-md-3'
                      : 'col-md-4'
                  "
                >
                  <v-autocomplete
                    v-model="order_details.currency"
                    label="Currency"
                    item-text="text"
                    item-value="text"
                    :items="serverData.currencies"
                    clearable
                    outlined
                    dense
                    :error-messages="currencyErrors"
                    @input="$v.order_details.currency.$touch()"
                    @blur="$v.order_details.currency.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    class
                    v-model="order_details.total_price"
                    type="number"
                    label="Total price"
                    clearable
                    outlined
                    dense
                    @change="
                      () => validateMinValue('total_price', 0, 'order_details')
                    "
                    :error-messages="total_priceErrors"
                    @input="$v.order_details.total_price.$touch()"
                    @blur="$v.order_details.total_price.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.parcel_weight"
                    label="Parcel weight"
                    clearable
                    outlined
                    dense
                    type="number"
                    :error-messages="parcel_weightErrors"
                    @input="$v.order_details.parcel_weight.$touch()"
                    @blur="$v.order_details.parcel_weight.$touch()"
                    @change="
                      () =>
                        validateMinValue('parcel_weight', 0, 'order_details')
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.parcel_volume"
                    label="Parcel volume"
                    clearable
                    outlined
                    dense
                    type="number"
                    :error-messages="parcel_volumeErrors"
                    @input="$v.order_details.parcel_volume.$touch()"
                    @blur="$v.order_details.parcel_volume.$touch()"
                    @change="
                      () =>
                        validateMinValue('parcel_volume', 0, 'order_details')
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.number_of_boxes"
                    label="Number of boxes"
                    :min="0"
                    clearable
                    outlined
                    dense
                    type="number"
                    :error-messages="number_of_boxesErrors"
                    @input="$v.order_details.number_of_boxes.$touch()"
                    @blur="$v.order_details.number_of_boxes.$touch()"
                    @change="
                      () =>
                        validateMinValue('number_of_boxes', 0, 'order_details')
                    "
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.shipping_price"
                    label="Shipping price"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () =>
                        validateMinValue('shipping_price', 0, 'order_details')
                    "
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.customs_value"
                    label="Customs value"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () =>
                        validateMinValue('customs_value', 0, 'order_details')
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.subtotal"
                    label="Subtotal"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () => validateMinValue('subtotal', 0, 'order_details')
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.total_discount"
                    label="Total discount"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () =>
                        validateMinValue('total_discount', 0, 'order_details')
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.total_tax"
                    label="Total tax"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () => validateMinValue('total_tax', 0, 'order_details')
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.tax_percentage"
                    label="Tax percentage"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () =>
                        validateMinValue('tax_percentage', 0, 'order_details')
                    "
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.total_declared_amount"
                    label="Total declared amount"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () =>
                        validateMinValue(
                          'total_declared_amount',
                          0,
                          'order_details'
                        )
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.cod_amount"
                    label="Cod amount"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () => validateMinValue('cod_amount', 0, 'order_details')
                    "
                    :error-messages="cod_amountErrors"
                    @input="$v.order_details.cod_amount.$touch()"
                    @blur="$v.order_details.cod_amount.$touch()"
                  />
                </div>

                <div class="col-12">
                  <v-text-field
                    class
                    v-model="order_details.note"
                    label="Comment"
                    clearable
                    outlined
                    dense
                  />
                </div>

                <div class="col-12">
                  <v-text-field
                    v-model="order_details.gift_message"
                    label="Gift message"
                    clearable
                    outlined
                    dense
                  />
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content step="1" class="pt-2">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <v-text-field
                    class
                    v-model="consignee.company"
                    label="Company"
                    clearable
                    outlined
                    dense
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    class
                    v-model="consignee.name"
                    label="Name"
                    clearable
                    outlined
                    dense
                    :error-messages="nameErrors"
                    @input="$v.consignee.name.$touch()"
                    @blur="$v.consignee.name.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    class
                    v-model="consignee.surname"
                    label="Surname"
                    clearable
                    outlined
                    dense
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="consignee.phone"
                    label="Phone"
                    clearable
                    outlined
                    dense
                    type="number"
                    :error-messages="phoneErrors"
                    @input="$v.consignee.phone.$touch()"
                    @blur="$v.consignee.phone.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <VueTelInputVuetify
                    v-model="consignee.mobile"
                    label="Mobile"
                    type="number"
                    clearable
                    outlined
                    dense
                    :inputOptions="{ showDialCode: false, tabindex: 0 }"
                    mode="international"
                    disabledFetchingCountry
                    defaultCountry="AE"
                    :error-messages="mobileErrors"
                    @input="$v.consignee.mobile.$touch()"
                    @blur="$v.consignee.mobile.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    class
                    v-model="consignee.email"
                    label="Email"
                    clearable
                    outlined
                    dense
                    :error-messages="emailErrors"
                    @input="$v.consignee.email.$touch()"
                    @blur="$v.consignee.email.$touch()"
                  />
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-2">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <v-autocomplete
                    @change="onCountryChange"
                    v-model="$v.shipping_address.country.$model"
                    label="Country"
                    item-text="text"
                    item-value="index"
                    :items="serverData.countries"
                    clearable
                    outlined
                    dense
                    :error-messages="countryErrors"
                    @input="$v.shipping_address.country.$touch()"
                    @blur="$v.shipping_address.country.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <v-combobox
                    ref="stateCombo"
                    v-model="$v.shipping_address.state.$model"
                    :search-input.sync="state_text"
                    :items="serverData.states"
                    :disabled="isStatesLoaded"
                    :rules="[validationValueCheck('state')]"
                    item-text="title"
                    item-value="title"
                    label="State"
                    persistent-hint
                    outlined
                    dense
                    :error-messages="stateErrors"
                    @input="$v.shipping_address.state.$touch()"
                    @blur="$v.shipping_address.state.$touch()"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{
                              state_text
                            }}</strong
                            >".
                            <button
                              v-if="!isClient"
                              class="btn btn-info pl-2"
                              @click="
                                () =>
                                  createNewItem(
                                    'state',
                                    shipping_address.country,
                                    onCountryChange,
                                    state_text
                                  )
                              "
                            >
                              Create new
                            </button>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-12 col-sm-6">
                  <v-combobox
                    ref="cityCombo"
                    v-model="$v.shipping_address.city.$model"
                    :search-input.sync="city_text"
                    :items="serverData.cities"
                    :disabled="isCitiesLoaded"
                    :rules="[validationValueCheck('city')]"
                    item-text="title"
                    item-value="title"
                    label="City"
                    persistent-hint
                    outlined
                    dense
                    :error-messages="cityErrors"
                    @input="$v.shipping_address.city.$touch()"
                    @blur="$v.shipping_address.city.$touch()"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ city_text }}</strong
                            >".
                            <button
                              v-if="!isClient"
                              class="btn btn-info pl-2"
                              @click="
                                () =>
                                  createNewItem(
                                    'city',
                                    shipping_address.state.id,
                                    onStateChange,
                                    city_text
                                  )
                              "
                            >
                              Create new
                            </button>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-12 col-sm-6">
                  <v-combobox
                    :disabled="isAreasLoaded"
                    v-model="shipping_address.area"
                    :search-input.sync="area_text"
                    :items="serverData.areas"
                    :rules="[validationValueCheck('area')]"
                    item-text="title"
                    item-value="title"
                    label="Area"
                    persistent-hint
                    outlined
                    dense
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ area_text }}</strong
                            >".
                            <button
                              v-if="!isClient"
                              class="btn btn-info pl-2"
                              @click="
                                () =>
                                  createNewItem(
                                    'area',
                                    shipping_address.city.id,
                                    onCityChange,
                                    area_text
                                  )
                              "
                            >
                              Create new
                            </button>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="$v.shipping_address.address_1.$model"
                    label="Address 1"
                    clearable
                    outlined
                    dense
                    :error-messages="address_1Errors"
                    @input="$v.shipping_address.address_1.$touch()"
                    @blur="$v.shipping_address.address_1.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="shipping_address.address_2"
                    label="Address 2"
                    clearable
                    outlined
                    dense
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <v-menu
                    v-model="required_delivery_timeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="shipping_address.required_delivery_time"
                        label="Required delivery time"
                        prepend-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="shipping_address.required_delivery_time"
                      @input="required_delivery_timeMenu = false"
                    />
                  </v-menu>
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="shipping_address.postal_code"
                    label="Postal code"
                    clearable
                    outlined
                    dense
                  />
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-2">
              <v-card color="basil" flat>
                <v-col cols="12">
                  <sku-datatable
                    :setSku="setSkus"
                    :skus="formData.skus"
                    :skuItem="[]"
                    :clientId="userId"
                  />
                </v-col>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <CreateLocation
          :location-id="getLocationId"
          :location-type="getLocationType"
          :location-function="getLocationFunc"
          :locationSetValue="setTextValue"
          :initValue="getLocationInitValue"
          ref="location"
        />

        <div class="d-flex align-center modal-action flex-wrap pt-4">
          <div class="justify-content-between align-center d-flex col-12">
            <v-btn
              v-if="backState"
              class="order-1 order-sm-1"
              @click="() => nextStep('prev')"
              color="primary"
              dark
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <div v-else class="order-1 order-sm-1"></div>
            <div
              class="order-2 order-sm-2 d-flex flex-wrap justify-content-center align-center"
              :class="{ invisible: nextState }"
            >
              <button
                type="submit"
                class="btn btn-danger px-5 py-3 mx-2 order-2 mt-1 mt-sm-0"
                @click="toggleModal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-info px-5 py-3 mx-2 order-1 mt-1 mt-sm-0"
                @click="submitCreateForm"
              >
                Create Order
              </button>
            </div>
            <v-btn
              v-if="nextState"
              class="order-3 order-sm-3"
              @click="() => nextStep('next')"
              color="primary"
              dark
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
            <div v-else class="order-3 order-sm-3"></div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import SkuDatatable from "@/own/components/fulfillment/orders/skuContainerCreate/SkuDatatable";
import { validationMixin } from "vuelidate";
import { required, requiredIf, email, helpers } from "vuelidate/lib/validators";
import CreateLocation from "./CreateLocation.vue";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

import {
  handleCityValueChange,
  handleCountryValueChange,
  handleStateValueChange,
} from "@/own/libs/handle-locations";

export default {
  name: "AddItem",
  mixins: [validationMixin, fieldValueValidation],
  components: {
    SkuDatatable,
    CreateLocation,
    VueTelInputVuetify,
  },

  props: {
    refresher: {
      required: true,
      type: Function,
    },
    pageLoader: {
      required: true,
      type: Function,
    },
  },

  validations() {
    return {
      order_details: {
        customer_id: {
          required: requiredIf(function () {
            return !this.isClient;
          }),
        },
        client_reference: { required },
        shipping_method: { required },
        payment_type: { required },
        currency: { required },
        total_price: { required },
        order_type: { required },
        parcel_weight: {
          required: requiredIf(function () {
            return (
              this.order_details.order_type === "XDOCK" ||
              this.order_details.order_type === "XSDOCK" ||
              this.order_details.order_type === "FMXDOCK" ||
              this.order_details.order_type === "FMXDOCKNT"
            );
          }),
        },
        parcel_volume: {
          required: requiredIf(function () {
            return (
              this.order_details.order_type === "XDOCK" ||
              this.order_details.order_type === "XSDOCK" ||
              this.order_details.order_type === "FMXDOCK" ||
              this.order_details.order_type === "FMXDOCKNT"
            );
          }),
        },
        number_of_boxes: {
          required: requiredIf(function () {
            return (
              this.order_details.order_type === "XDOCK" ||
              this.order_details.order_type === "XSDOCK" ||
              this.order_details.order_type === "FMXDOCK" ||
              this.order_details.order_type === "FMXDOCKNT"
            );
          }),
        },
        cod_amount: {
          required: requiredIf(function () {
            return this.order_details.payment_type === "COD";
          }),
        },
        payment_mode: {
          required: requiredIf(function () {
            return this.order_details.payment_type == 'COD';
          }),
        },
      },

      shipping_address: {
        country: { required },
        state: { required },
        city: { required },
        address_1: { required },
      },
      consignee: {
        name: { required },

        // eslint-disable-next-line no-useless-escape
        // /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        phone: {
          required,
          numeric: helpers.regex("numeric", /^\+?\d{5,15}$/),
        },
        mobile: { required },
        email: { email },
      },
    };
  },
  data: () => ({
    tabValidationState: [true, true, true, true, true, true],
    required_delivery_timeMenu: false,

    state_text: "",
    city_text: "",
    area_text: "",

    areaMessage: "",

    location_type: null,
    locaiton_id: null,
    location_function: null,
    location_initValue: null,

    //shipping_address
    shipping_address: {
      country: null,
      state: null,
      city: null,
      area: null,
      address_1: null,
      address_2: null,
      postal_code: null,
    },
    consignee: {
      company: null,
      name: null,
      surname: null,
      phone: null,
      mobile: null,
      email: null,
    },
    parcel: {
      total_weight: null,
      total_volume: null,
      box_number: null,
    },
    order_details: {
      awb: null,
      customer_id: null,
      order_status: null,
      client_reference: null,
      warehouse: null,
      total_price: null,
      customs_value: null,
      subtotal: null,
      total_discount: null,
      total_tax: null,
      required_delivery_time: null,
      carrier_option: "3PL",

      order_type: null,
      payment_type: null,
      currency: null,
      shipping_method: null,
      note: null,
      parcel_weight: null,
      parcel_volume: null,
      number_of_boxes: null,
      gift_message: null,
      shipping_price: null,
      tax_percentage: null,
      total_declared_amount: null,
      cod_amount: null,
      payment_mode: null,
    },
    finance: {},
    formData: {
      skus: [],
    },

    dialog: false,
    serverData: null,
    tab: 0,
    categories: [
      {
        name: "Order Details",
        valid: true,
        rules: (el) => {
          try {
            return el[`$v`][`order_details`].$invalid;
          } catch {
            return true;
          }
        },
      },
      {
        name: "Consignee Details",
        valid: true,
        rules: (el) => {
          try {
            return el[`$v`][`consignee`].$invalid;
          } catch (error) {
            // console.log(error);
            return true;
          }
        },
      },
      {
        name: "Address",
        valid: true,
        rules: (el) => {
          try {
            return el[`$v`][`shipping_address`].$invalid;
          } catch {
            return true;
          }
        },
      },
      {
        name: "Items",
        valid: true,
        rules: (el) => {
          try {
            return !el.formData.skus.length > 0;
          } catch (error) {
            // console.log(error);
            return true;
          }
        },
      },
    ],

    updateValueTimer: setTimeout(() => {}, 0),
    cityFetchTimer: null,
    areaFetchTimer: null,
    locationFetchTriggerTime: 600,
  }),
  methods: {
    nextStep(type) {
      // console.log("ssssss", this.consignee.mobile);
      // if (this.consignee.mobile)
      //   console.log("replace", this.consignee.mobile.replace(/\s/g, ""));

      this.updateValidationStateOfSteps();
      if (type === "next") {
        if (this.tab < 3) {
          this.tab += 1;
        }
      } else {
        if (this.tab > 0) {
          this.tab -= 1;
        }
      }
    },
    updateValue(type) {
      clearTimeout(this.updateValueTimer);
      this.updateValueTimer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, this.locationFetchTriggerTime);
    },
    async onBlurValueCheck(type) {
      switch (type) {
        case "state": {
          if (this.state_text == "") {
            return;
          }

          const item = this.serverData?.states?.find(
            (state) => state.title === this.state_text
          );

          if (!item) {
            this.serverData.cities = null;
            this.serverData.areas = null;
          } else {
            if (item.title != this.state_text) {
              this.state_text = item.title;
            } else {
              await this.onStateChange(item.title);
              this.$refs.stateCombo.blur();
            }
          }

          break;
        }
        case "city": {
          if (this.city_text == "") {
            return;
          }

          const item = this.serverData?.cities?.find(
            (city) => city.title === this.city_text
          );
          if (!item) {
            this.serverData.areas = null;
          } else {
            if (item.title != this.city_text) {
              this.city_text = item.title;
            } else {
              await this.onCityChange(item.title);
              this.$refs.cityCombo.blur();
            }
          }

          break;
        }
      }
    },
    validationValueCheck(type) {
      let validation = null;
      switch (type) {
        case "state":
          if (this.state_text == "") {
            return;
          }
          try {
            if (
              !this.serverData?.states?.find(
                (state) => state.title === this.state_text
              )
            ) {
              validation = "Please, select valid state!";
              // this.serverData.cities = null;
              // this.serverData.areas = null;
            }
          } catch {
            validation = "Please, select valid state!";
          }

          break;
        case "city":
          if (this.city_text == "") {
            return;
          }
          try {
            if (
              !this.serverData?.cities?.find(
                (city) => city.title === this.city_text
              )
            ) {
              validation = "Please, select valid city!";
              // this.serverData.areas = null;
            }
          } catch {
            validation = "Please, select valid state!";
          }

          break;
        case "area":
          try {
            if (
              !this.serverData.areas.find(
                (area) => area.title === this.area_text
              )
            ) {
              validation = "Please, select valid area!";
            }
          } catch {
            validation = "Please, select valid city!";
          }
          break;
      }
      return validation;
    },
    setTextValue(type, value = "") {
      switch (type) {
        case "state":
          this.state_text = value;
          if (value == "") {
            return;
          }
          const state = this.serverData?.states?.find(
            (state) => state.title === this.state_text
          );
          this.address.state = state;
          // this.onStateChange(this.address.state.title);

          break;
        case "city":
          this.city_text = value;
          if (value == "") {
            return;
          }
          const city = this.serverData?.cities?.find(
            (city) => city.title === this.city_text
          );
          if (city) {
            this.address.city = city;
          }

          // this.onCityChange(this.address.city.title);

          break;
        case "area":
          this.area_text = value;

          if (value == "") {
            return;
          }
          const area = this.serverData?.areas?.find(
            (area) => area.title === this.area_text
          );
          if (area) {
            this.address.area = area;
          }

          break;
      }
    },
    createNewItem(type, id, func, value) {
      this.$refs[`${type}Combo`].blur();
      this.location_type = type;
      this.locaiton_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },
    updateValidationStateOfSteps() {
      // console.log(`working`);
      this.$v.$touch();
      this.categories.forEach((cat) => {
        cat.valid = !cat.rules(this);
        // console.log(cat);
      });
    },
    resetValidationStateOfSteps() {
      this.categories.forEach((cat) => {
        cat.valid = true;
      });
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    handleFormValidation(fieldName, dataName) {
      //  phone, consignee
      const errors = [];
      // console.log("validation", this.$v[`${dataName}`][fieldName]);
      if (!this.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }

      if ("numeric" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].numeric &&
          errors.push("Please add valid number");
      }
      if ("email" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    async loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.itemForAction };
      await ApiService.post(`/fulfillment/orders/create`, data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm().finally(() => {
            this.pageLoader(false);
            this.updateValidationStateOfSteps();
            this.order_details.warehouse = "IQWH01";
          });
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },

    submitCreateForm() {
      this.updateValidationStateOfSteps();
      if (this.$v.$invalid) {
        return;
      }
      if (!this.formData.skus.length) {
        Swal.fire({
          title: "Warning",
          html: `<div>At least one sku is required!</div>`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }

      this.pageLoader(true);
      this.parcel = {
        total_weight: this.order_details.parcel_weight,
        total_volume: this.order_details.parcel_volume,
        box_number: this.order_details.number_of_boxes,
      };
      let data = JSON.parse(
        JSON.stringify({
          ...this.formData,
          ...this.order_details,
          parcel: this.parcel,
          consignee: this.consignee,
          shipping_address: this.shipping_address,
        })
      );
      data.shipping_address.country = this.serverData.countries.find(
        (country) => country.index === this.shipping_address.country
      ).country_code;
      data.shipping_address.state = this.shipping_address.state.title;
      data.shipping_address.city = this.shipping_address.city.title;
      if (this.shipping_address.area) {
        data.shipping_address.area = this.shipping_address.area.title;
      }
      if (data.consignee.phone[0] === "+") {
        data.consignee.phone = data.consignee.phone.slice(1);
        // data.consignee.phone = data.consignee.phone.slice(1).replace(/\s/g, "");
      }
      data.consignee.mobile = data.consignee.mobile.replace(/\s/g, "");

      ApiService.post(`/fulfillment/orders/store`, data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `Order has been created`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.refresher();
          this.toggleModal();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    async resetEditForm() {
      this.$v.$reset();

      this.formData = {
        skus: [],
      };
      this.order_details = {
        awb: null,
        customer_id: null,
        order_status: null,
        client_reference: null,
        warehouse: null,
        total_price: null,
        customs_value: null,
        subtotal: null,
        total_discount: null,
        total_tax: null,
        required_delivery_time: null,
        carrier_option: "3PL",

        order_type: null,
        payment_type: null,
        currency: null,
        shipping_method: null,
        note: null,
        parcel_weight: null,
        parcel_volume: null,
        number_of_boxes: null,
        gift_message: null,
        shipping_price: null,
        tax_percentage: null,
        total_declared_amount: null,
        cod_amount: null,
        payment_mode: null,
      };
      this.parcel = {
        total_weight: null,
        total_volume: null,
        box_number: null,
      };
      this.consignee = {
        company: null,
        name: null,
        surname: null,
        phone: null,
        mobile: null,
        email: null,
      };
      this.shipping_address = {
        country: null,
        state: null,
        city: null,
        area: null,
        address_1: null,
        address_2: null,
        postal_code: null,
      };
      this.tab = 0;
      await this.resetValidationStateOfSteps();
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
    async onCountryChange(val, safe = true) {
      this.pageLoader(true);
      try {
        await handleCountryValueChange.call(
          this,
          val,
          safe,
          "shipping_address"
        );
        await this.updateValue("state");
      } catch {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
    },
    async onStateChange(value, safe = true, is_id = false) {
      // clearTimeout(this.cityFetchTimer);
      // this.cityFetchTimer = setTimeout(async () => {
      this.pageLoader(true);
      try {
        await handleStateValueChange.call(
          this,
          value,
          safe,
          is_id,
          "shipping_address"
        );
        await this.updateValue("city");
      } catch {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
      // }, this.locationFetchTriggerTime);
    },
    async onCityChange(value, safe = true, is_id = false) {
      // clearTimeout(this.areaFetchTimer);
      // this.areaFetchTimer = setTimeout(async () => {
      this.pageLoader(true);
      try {
        await handleCityValueChange.call(
          this,
          value,
          safe,
          is_id,
          "shipping_address"
        );
        this.areaMessageGenerator();
      } catch {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
      // }, this.locationFetchTriggerTime);
    },
  },
  computed: {
    backState() {
      return this.tab !== 0;
    },
    nextState() {
      return this.tab !== 3;
    },
    getLocationType() {
      return this.location_type;
    },
    getLocationId() {
      return this.locaiton_id;
    },
    getLocationFunc() {
      return this.location_function;
    },
    getLocationInitValue() {
      return this.location_initValue;
    },
    get_is_unit_price_required() {
      let data = null;
      try {
        data = this.serverData.countries.find(
          (country) => country.index === this.shipping_address.country
        ).unit_price_required;
      } catch {
        data = null;
      }
      return data;
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    isStepperReady() {
      try {
        return this.$v ? true : false;
      } catch (error) {
        return false;
      }
    },
    userId: function () {
      return this.$store.getters.currentUser.data.is_client
        ? this.$store.getters.currentUser.data.id
        : this.order_details.customer_id;
    },
    states: function () {
      return this.serverData.states;
    },
    cities: function () {
      return this.serverData.cities;
    },
    areas: function () {
      return this.serverData.areas;
    },
    isStatesLoaded: function () {
      return !this.serverData.states;
    },
    isCitiesLoaded: function () {
      return !this.serverData.cities;
    },
    isAreasLoaded: function () {
      return !this.serverData.areas;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },

    customer_idErrors: function () {
      return this.handleFormValidation("customer_id", "order_details");
    },
    order_typeErrors: function () {
      return this.handleFormValidation("order_type", "order_details");
    },
    client_referenceErrors: function () {
      return this.handleFormValidation("client_reference", "order_details");
    },
    shipping_methodErrors: function () {
      return this.handleFormValidation("shipping_method", "order_details");
    },
    payment_typeErrors: function () {
      return this.handleFormValidation("payment_type", "order_details");
    },
    currencyErrors: function () {
      return this.handleFormValidation("currency", "order_details");
    },
    total_priceErrors: function () {
      return this.handleFormValidation("total_price", "order_details");
    },
    parcel_weightErrors: function () {
      return this.handleFormValidation("parcel_weight", "order_details");
    },
    parcel_volumeErrors: function () {
      return this.handleFormValidation("parcel_volume", "order_details");
    },
    number_of_boxesErrors: function () {
      return this.handleFormValidation("number_of_boxes", "order_details");
    },
    cod_amountErrors: function () {
      return this.handleFormValidation("cod_amount", "order_details");
    },
    payment_modeErrors: function () {
      return this.handleFormValidation("payment_mode", "order_details");
    },
    nameErrors: function () {
      return this.handleFormValidation("name", "consignee");
    },
    phoneErrors: function () {
      return this.handleFormValidation("phone", "consignee");
    },
    mobileErrors: function () {
      return this.handleFormValidation("mobile", "consignee");
    },
    emailErrors: function () {
      return this.handleFormValidation("email", "consignee");
    },
    address_1Errors: function () {
      return this.handleFormValidation("address_1", "shipping_address");
    },
    countryErrors: function () {
      return this.handleFormValidation("country", "shipping_address");
    },
    stateErrors: function () {
      return this.handleFormValidation("state", "shipping_address");
    },
    cityErrors: function () {
      return this.handleFormValidation("city", "shipping_address");
    },
  },
  watch: {
    state_text: {
      handler(value, oldValue) {
        if (!this.serverData.countries) {
          this.serverData.states = null;
          this.serverData.cities = null;
          this.serverData.areas = null;
          return;
        }
        if (value == "" || value == null || value == undefined) {
          return;
        }
        if (value == oldValue) {
          return;
        }
        this.updateValue("state");
      },
      deep: true,
    },
    city_text: {
      handler(value, oldValue) {
        if (!this.serverData.states) {
          this.serverData.cities = null;
          this.serverData.areas = null;
          return;
        }
        if (value == "" || value == null || value == undefined) {
          return;
        }
        if (value == oldValue) {
          return;
        }
        this.updateValue("city");
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.modal-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
}

.modal-root {
  background-color: white !important;
  height: 100%;
}

.modal-action {
  justify-self: flex-end;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}

.form-body {
  max-height: 64vh !important;
  height: 64vh !important;
  overflow-y: scroll;
}

.step-head {
}

.step-head-main {
  display: flex;
  flex-flow: row;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}
</style>
